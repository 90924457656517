import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
import Gallery from './components/Gallery';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import HomeServices from './components/HomeServices';
import HaircutStylingService from './components/HaircutStylingService';
import ManicurePedicureService from './components/ManicurePedicureService';
import PermanentEyebrowService from './components/PermanentEyebroService';
// Import other service components here
import FacialSkincareService from './components/FacialSkincareService';
import MakeupService from './components/MakeupService';
import WaxingService from './components/WaxingService';
import SpaService from './components/SpaService';
import Testimonial from './components/Testimonial';
import HomeAboutUs from './components/HomeAboutUs';
import WartRemoval from './components/WartRemoval';
// import Layout from './components/Layout';
function App() {
  return (
    <Router>
    
      <div>

        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/homeabout" element={<HomeAboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/HomeServices" element={<HomeServices />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services/haircut-styling" element={<HaircutStylingService />} />
          <Route path="/services/manicure-pedicure" element={<ManicurePedicureService />} />
          <Route path="/services/permanent-eyebrow" element={<PermanentEyebrowService />} />
          <Route path="/services/wart-removal" element={<WartRemoval />} />
          {/* Add routes for other service components */}
          <Route path="/services/facial-skincare" element={<FacialSkincareService />} />
          <Route path="/services/makeup" element={<MakeupService />} />
          <Route path="/services/waxing" element={<WaxingService />} />
          <Route path="/services/spa" element={<SpaService />} />
        
          
        </Routes>
        
      </div>
      
    </Router>
    
  );
}

export default App;
