import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Services.css';
import Footer from './Footer';

const Services: React.FC = () => {
  const navigate = useNavigate();

  // Mock service data
  const serviceData = [
    {
      id: '1',
      title: 'Haircut & Styling',
      image: "/assets/service1.jpg",
      details: 'Experience our professional haircut and styling services to enhance your look.'
    },
    {
      id: '2',
      title: 'Manicure & Pedicure',
      image: "/assets/service2.jpg",
      details: 'Relax and pamper yourself with our luxurious manicure and pedicure treatments.'
    },
    {
      id: '3',
      title: 'Facial & Skincare',
      image: "/assets/service3.jpg",
      details: 'Revitalize your skin with our range of facial and skincare treatments tailored to your needs.'
    },
    {
      id: '4',
      title: 'Makeup',
      image: "/assets/service4.jpg",
      details: 'Transform your look with our professional makeup services for any occasion.'
    },
    {
      id: '5',
      title: 'Waxing',
      image: "/assets/service5.jpg",
      details: 'Experience smooth and hair-free skin with our expert waxing services.'
    },
    {
      id: '6',
      title: 'Spa',
      image: "/assets/service6.jpg",
      details: 'Relieve tension and stress with our rejuvenating massage therapies.'
    },
    {
      id: '7',
      title: 'Permanent Eyebrow',
      image: "/assets/PermEyebrow.jpg",
      details: 'Unlock the allure of perfectly shaped and defined brows with our expert permanent eyebrow solutions.'
    },
    {
      id: '8',
      title: 'Wart Removal',
      image: "/assets/WartRemoval.jpg",
      details: 'Experience the confidence of clear, smooth skin with our professional wart removal services.'
    },
  ];

  // Function to navigate to the service detail page and scroll to the top
  const handleLinkClick = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="services-container-yarr">
        <h1 className="services-title-yarr">Our Services</h1>
        <div className="services-grid-yarr">
          {serviceData.map((service) => (
            <div 
              key={service.id} 
              className="services-service-card-yarr" 
              onClick={() => handleLinkClick(`/services/${service.title.toLowerCase().replace(/&/g, '').replace(/\s+/g, '-')}`)}
            >
              <div className="services-service-image-container-yarr">
                <img src={service.image} alt={service.title} className="services-service-image-yarr" />
              </div>
              <div className="services-service-details-container-yarr">
                <h2 className="services-service-title-yarr">{service.title}</h2>
                <p className="services-service-details-yarr">{service.details}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
