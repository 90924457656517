import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/HomeServices.css';

const HomeServices: React.FC = () => {
  const navigate = useNavigate();

  const handleLinkClick = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className="services-container">
      <h1>Our Services</h1>
      <div className="services-row">
        <ServiceItem image="/assets/service1.jpg" title="Haircut & Styling" onClick={handleLinkClick} />
        <ServiceItem image="/assets/service2.jpg" title="Manicure & Pedicure" onClick={handleLinkClick} />
        <ServiceItem image="/assets/service3.jpg" title="Facial & Skincare" onClick={handleLinkClick} />
        <ServiceItem image="/assets/PermEyebrow.jpg" title="Permanent Eyebrow" onClick={handleLinkClick} />
      </div>
      <div className="services-row">
        <ServiceItem image="/assets/service4.jpg" title="Makeup" onClick={handleLinkClick} />
        <ServiceItem image="/assets/service5.jpg" title="Waxing" onClick={handleLinkClick} />
        <ServiceItem image="/assets/service6.jpg" title="Spa" onClick={handleLinkClick} />
        <ServiceItem image="/assets/WartRemoval.jpg" title="Wart Removal" onClick={handleLinkClick} />
      </div>
    </div>
  );
}

interface ServiceItemProps {
  image: string;
  title: string;
  onClick: (path: string) => void;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ image, title, onClick }) => {
  const handleClick = () => {
    onClick(`/services/${title.toLowerCase().replace(/&/g, '').replace(/\s+/g, '-')}`);
  };

  return (
    <div className="service-item" onClick={handleClick}>
      <div className="service-image">
        <img src={image} alt={title} />
      </div>
      <h2>{title}</h2>
    </div>
  );
}

export default HomeServices;
