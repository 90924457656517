export interface FacialSkincareService {
    title: string;
    description: string;
    price: number;
    image: string;
  }
  
  export const facialSkincareData: FacialSkincareService[] = [
    {
      title: "Face Clean Up",
      description: "At Rejuvenate, we offer face clean up options for all skin types. A face scrub to remove any impurities hidden in your pores followed by a face massage nourishes your face from within, giving your skin a natural glow!",
      price: 500,
      image: "/assets/Faceclean.jpg"
    },
    {
      title: "Facial",
      description: "We offer A)Classic Facial B)Basic Facial C)Advance Facial.A relaxing facial massage helps to improve circulation",
      price: 500,
      image: "/assets/Facial.jpg"
    },
    {
      title: "De-tan",
      description: "Indulge in our Detan Facial, a specialized treatment crafted to combat sun damage and restore your skin's natural radiance.",
      price: 500,
      image: "/assets/Detan.jpg"
    },
    {
      title: "Facemask",
      description: "Indulge in our luxurious Facial Mask treatment, designed to provide your skin with a revitalizing boost and a radiant glow.",
      price: 500,
      image: "/assets/Facemask.jpg"
    }
    // Add more facial skincare services as needed
  ];
  