// ClickToCallButton.tsx

import React from 'react';
import { Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

const ClickToCallButton = () => {
  return (
    <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 999 }}>
      <Button
        variant="contained"
        style={{
          backgroundColor: '#FF9800', // Material-UI orange color
          color: 'white',
          textTransform: 'none',
          borderRadius: '20px', // Rounded rectangular shape
          padding: '8px 16px', // Padding for the text inside the button
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Adding shadow
        }}
        href="tel:8623917369"
        startIcon={<PhoneIcon />}
      >
        Call Us
      </Button>
    </div>
  );
};

export default ClickToCallButton;
