import React from 'react';
import { Button } from '@mui/material';
import { Face, Spa, LocalHospital, EmojiPeople, Weekend ,Face3,Mood} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import '../styles/WaxingService.css';
import Footer from './Footer';
import { waxingServiceData } from './waxingServiceData';

const WaxingService: React.FC = () => {
  return (
    <div>
    <div className="waxing-services-container">
      {waxingServiceData.map((service, index) => (
        <div className="waxing-service-container" key={index}>
          <div className="waxing-service-image">
            <img src={service.image} alt={service.title} />
          </div>
          <div className="waxing-service-details">
            <h3 className="title">{service.title}</h3>
            <p className="info">{service.description}</p>
            {/* <div className="custom-button">Price: ₹{service.price}</div> */}
          </div>
        </div>
      ))}

      <div className="other-services-container">
        <div className="other-services">
          <h3>Explore Other Services:</h3>
          <div className="service-buttons">
            <div className="service-button">
              <Link to="/services/manicure-pedicure">
                <Weekend />
                <span>Manicure & Pedicure</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/facial-skincare">
                <Face />
                <span>Facial & Skin Care</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/makeup">
                <LocalHospital />
                <span>Makeup Services</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/spa">
                <Spa />
                <span>Spa Service</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/haircut-styling">
                <EmojiPeople />
                <span>Haircut & Styling</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/permanent-eyebrow">
                <Face3 />
                <span>Permanent Eyebrow</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/wart-removal">
                <Mood />
                <span>Wart Removal</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
}

export default WaxingService;
