import React from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM correctly
//import './styles/styles.css';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') // Use ReactDOM.render to render the root component
);
