// Testimonial.tsx
import React, { useState, useEffect } from 'react';
import '../styles/Testimonial.css';

const Testimonial: React.FC = () => {
  const testimonials = [
    {
      name: 'Swati Darade',
      review: 'Best services with perfection. She gives useful suggestions for healthy skincare. Facial is awesome by her. ❤️',
    },
    {
      name: 'Shwetha Umesh',
      review: 'Very good service. The owner has very good experience in the field and good knowledge about the products. Provides service according to one\'s skin and hair type.',
    },
    {
      name: 'Rachna Chavda',
      review: 'The owner of this salon is such a humble person. She cares and takes efforts for hair and skin personally. Hygiene and clean beauty salon.',
    },
    {
      name: 'Yogini Thorat',
      review: 'Owner and staff are very polite. Clean and hygienic parlour.',
    },
    {
      name: 'Alka Suryawanshi',
      review: 'Nice staff and nice treatment.',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === testimonials.length - 1 ? 0 : prevSlide + 1));
    }, 2000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  const handleDotClick = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="testimonial-container">
      <h2 className="testimonial-heading">Client Testimonials</h2>
      <div className="testimonial-slider">
        {testimonials.map((testimonial, index) => (
          <div key={index} className={`testimonial ${index === currentSlide ? 'active' : ''}`}>
            <p className="testimonial-text">{testimonial.review}</p>
            <p className="testimonial-name">- {testimonial.name}</p>
          </div>
        ))}
      </div>
      <div className="slider-dots">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
