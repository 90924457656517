// import GalleryImage1 from '/assets/GalleryImage1.jpg';
// import GalleryImage2 from '/assets/GalleryImage2.jpg';
// import GalleryImage3 from "/assets/GalleryImage3.jpg";
// import GalleryImage4 from '/assets/GalleryImage4.jpg';
// import GalleryImage5 from '/assets/GalleryImage5.jpg';
// import GalleryImage6 from '/assets/GalleryImage6.jpg';
// import GalleryImage7 from '/assets/GalleryImage7.jpg';
// import GalleryImage8 from '/assets/GalleryImage8.jpg';
// import GalleryImage9 from '/assets/GalleryImage9.jpg';
// import GalleryImage10 from '/assets/GalleryImage10.jpg';
// import GalleryImage11 from '/assets/GalleryImage11.jpg';

export const images = {
  galleryImage1: "/assets/Gallerysmall/GalleryImage1.jpg",
  galleryImage2: "/assets/Gallerysmall/GalleryImage2.jpg",
  galleryImage3: "/assets/Gallerysmall/GalleryImage3.jpg",
  galleryImage4: "/assets/Gallerysmall/GalleryImage4.jpg",
  galleryImage5: "/assets/Gallerysmall/GalleryImage5.jpg",
  galleryImage6: "/assets/Gallerysmall/GalleryImage6.jpg",
  galleryImage7:"/assets/Gallerysmall/GalleryImage7.jpg",
  galleryImage8: "/assets/Gallerysmall/GalleryImage8.jpg",
  galleryImage9: "/assets/Gallerysmall/GalleryImage9.jpg",
  galleryImage10: "/assets/Gallerysmall/GalleryImage10.jpg",
  galleryImage11: "/assets/Gallerysmall/GalleryImage11.jpg",
  // Add other images here
};
