import React from 'react';
import { Link } from 'react-router-dom';
import { facialSkincareData } from './facialSkincareData'; // Import your data file
import '../styles/FacialSkincareService.css'; 
import Footer from './Footer';
import { Button } from '@mui/material';
import { Face, Spa, LocalHospital, EmojiPeople, Weekend ,Face3, Mood} from '@mui/icons-material';

const FacialSkincareService: React.FC = () => {
  return (
    <div>
    <div className="facial-services-container">
      {facialSkincareData.map((service, index) => (
        <div className="facial-service-container" key={index}>
          <div className="facial-service-image">
            <img src={service.image} alt={service.title} />
          </div>
          <div className="facial-service-details">
            <h3 className="facial-title">{service.title}</h3>
            <p className="facial-info">{service.description}</p>
            {/* <div className="facial-custom-button">Price: ₹{service.price}</div> */}
          </div>
        </div>
      ))}

      <div className="other-services-container">
        <div className="other-services">
          <h3>Explore Other Services:</h3>
          <div className="service-buttons">
            <div className="service-button">
              <Link to="/services/haircut-styling">
                <Weekend />
                <span>Haircut & Styling</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/manicure-pedicure">
                <Face />
                <span>Manicure & Pedicure</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/makeup">
                <LocalHospital />
                <span>Makeup Services</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/spa">
                <Spa />
                <span>Spa Service</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/waxing">
                <EmojiPeople />
                <span>Waxing Service</span>
              </Link>
            </div>
            
            <div className="service-button">
              <Link to="/services/permanent-eyebrow">
                <Face3 />
                <span>Permanent Eyebrow</span>
              </Link>
            </div>
            <div className="service-button">
              <Link to="/services/wart-removal">
                <Mood />
                <span>Wart Removal</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
}

export default FacialSkincareService;
