// Home.tsx
import React, { useState, useEffect } from "react";
import "../styles/Home.css";
// import heroBg1 from "../assets/hero-bg1.jpg";
// import heroBg2 from "../assets/hero-bg2.jpg";
// import heroBg3 from "../assets/hero-bg3.jpg";


import Footer from "./Footer";
import HomeServices from "./HomeServices";
import Testimonial from "./Testimonial";
import HomeAboutUs from "./HomeAboutUs";

const Home: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = ["/assets/hero-bg1.jpg", "/assets/hero-bg2.jpg", "/assets/hero-bg3.jpg"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div id="home">
      <header
        className="hero"
        style={{
          backgroundImage: `url(${images[currentSlide]})`,
          position: "relative"
        }}
      >
        {/* Overlay */}
        <div className="overlay"></div>

        {/* Hero content */}
        <div className="hero-content">
          <h1 className="hero-title">Escape to <span>Rejuvenate</span></h1>
          <h2 className="hero-subtitle">Where Beauty Finds Its Sanctuary</h2>
        </div>

        {/* Slider dots */}
        <div className="slider-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => setCurrentSlide(index)}
            ></span>
          ))}
        </div>
      </header>

      <HomeAboutUs />
      <HomeServices />
      <Testimonial />
      <Footer />
    </div>
  );
};

export default Home;
