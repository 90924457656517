import React from 'react';
import '../styles/HomeAboutUs.css'; // Updated CSS file name

const HomeAboutUs: React.FC = () => {
  return (
    <div className="home-about-container">
      <div className="home-about-content">
        <h1 className="home-title">Welcome to Rejuvenate Beauty Salon And Spa</h1>
        <div className="home-description">
          <p>Step into a world of luxury and relaxation at Rejuvenate Beauty Salon and Spa. Our commitment is to provide you with an unparalleled experience where every detail is meticulously crafted to enhance your well-being.</p>
          <p>Indulge in our signature treatments tailored to your unique needs, from rejuvenating facials to soothing massages. Let our team of expert professionals pamper you and help you rediscover your inner beauty.</p>
        </div>
        {/* Card-like structure directly below the description */}
        <div className="home-certifications">
          <div className="creative-card">
            <img src="/assets/VLCCLogo.jpg" alt="VLCC Cosmetologist" className="creative-icon" />
            <h3 className="creative-title">Certified VLCC Cosmetologist</h3>
            <p className="creative-description">Our experts are certified by VLCC for cosmetic procedures.</p>
          </div>
          <div className="creative-card">
            <img src="/assets/JavedHabibLogo.jpg" alt="Javed Habib Certified" className="creative-icon" />
            <h3 className="creative-title">Javed Habib Certified</h3>
            <p className="creative-description">Certified by Javed Habib for hair styling and care.</p>
          </div>
          <div className="creative-card">
            <img src="/assets/ABTCLogo.png" alt="ABTC Certified" className="creative-icon" />
            <h3 className="creative-title">ABTC Certified</h3>
            <p className="creative-description">Our team is certified by ABTC for beauty treatments.</p>
          </div>
        </div>
        {/* Additional line below the card structure */}
        <p className="additional-line">WE ALSO PROVIDE PROFESSIONAL CLASSES</p>
      </div>
      <div className="home-about-image">
        <img src="/assets/about.jpg" alt="About Us" />
      </div>
    </div>
  );
}

export default HomeAboutUs;
