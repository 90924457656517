// ContactPage.tsx
import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaClock } from 'react-icons/fa';
import '../styles/Contact.css'; // Import CSS file for Contact page styling
import Footer from './Footer';

const ContactPage: React.FC = () => {
  return (
    <div>
      <h1 className="contact-title">Contact Us</h1>
    <div className="contact-page-container-unique">
      {/* Google Map */}
      <div className="google-map-container-unique">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15122.219593250766!2d73.74871388534729!3d18.6390794!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b95f0db347df%3A0x2edf38244be0fb04!2sRejuvenate%20Beauty%20Salon%20And%20Spa!5e0!3m2!1sen!2sin!4v1711091814295!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: "0" }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer"
          title="Google Map"
        ></iframe>
      </div>

      

      {/* Footer */}
      </div>
      <Footer />
    </div>

  );
}

export default ContactPage;
