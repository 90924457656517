export interface WaxingService {
    title: string;
    description: string;
    price: number;
    image: string;
  }
  
  export const waxingServiceData: WaxingService[] = [
    {
      title: 'Waxing',
      description: 'Our professionals take utmost care to ensure our customers have a comfortable and relatively less painful grooming experience',
      price: 500,
      image: '/assets/Waxing.jpg'
    },
    // Add more waxing services here as needed
  ];
  