import React from 'react';
import { Link } from 'react-router-dom';
import { Face, Spa, LocalHospital, EmojiPeople, Weekend, Face3, Mood,FaceRetouchingNatural } from '@mui/icons-material';
import Footer from './Footer';
import '../styles/PermanentEyebrowService.css'; // Ensure this CSS file includes the new styles

const PermanentEyebrowService: React.FC = () => {
  return (
    <div>
      <div className="permanent-eyebrow-video-container">
        <video controls>
          <source src="/assets/PermanentEyebrow.mp4" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="permanent-eyebrow-service-details">
        <h3 className="permanent-eyebrow-title">Permanent Eyebrow Services</h3>
        <p className="permanent-eyebrow-info">
          Our permanent eyebrow services include techniques such as microblading, ombre powder brows, and combo brows. These services are designed to give you natural-looking, fuller, and more defined eyebrows. Our experienced professionals use the latest tools and techniques to ensure the best results for each client.
        </p>
      </div>

      <div className="permanent-eyebrow-other-services-container">
        <div className="permanent-eyebrow-other-services">
          <h3>Explore Other Services:</h3>
          <div className="permanent-eyebrow-service-buttons">
            <div className="permanent-eyebrow-service-button">
              <Link to="/services/manicure-pedicure">
                <Weekend />
                <span>Manicure & Pedicure</span>
              </Link>
            </div>
            <div className="permanent-eyebrow-service-button">
              <Link to="/services/facial-skincare">
                <Face />
                <span>Facial & Skin Care</span>
              </Link>
            </div>
            <div className="permanent-eyebrow-service-button">
              <Link to="/services/haircut-styling">
                <LocalHospital />
                <span>Haircut & Styling</span>
              </Link>
            </div>
            <div className="permanent-eyebrow-service-button">
              <Link to="/services/spa">
                <Spa />
                <span>Spa Service</span>
              </Link>
            </div>
            <div className="permanent-eyebrow-service-button">
              <Link to="/services/waxing">
                <EmojiPeople />
                <span>Waxing Service</span>
              </Link>
            </div>
            <div className="permanent-eyebrow-service-button">
              <Link to="/services/makeup">
                < FaceRetouchingNatural/>
                <span>Makeup Service</span>
              </Link>
            </div>
            <div className="permanent-eyebrow-service-button">
              <Link to="/services/wart-removal">
                <Mood/>
                <span>Wart removal</span>
              </Link>
            </div>
           
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default PermanentEyebrowService;
