import { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import '../styles/Gallery.css';
import Footer from './Footer';
import { images } from "./imageImports";

const photos = [
  {
    src: images.galleryImage1,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage2,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage3,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage4,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage5,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage6,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage7,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage8,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage9,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage10,
    width: 800,
    height: 600,
  },
  {
    src: images.galleryImage11,
    width: 800,
    height: 600,
  },
//   {
//     src: "/assets/GalleryImage12.webp",
//     width: 800,
//     height: 600,
//   },
//   {
//     src: "/assets/GalleryImage13.webp",
//     width: 800,
//     height: 600,
//   },
//   {
//     src: "/assets/GalleryImage14.webp",
//     width: 800,
//     height: 600,
//   },
//   {
//     src: "/assets/GalleryImage15.webp",
//     width: 800,
//     height: 600,
//   },
];
const Gallery = () => {
  const [index, setIndex] = useState(-1);

  return (
    <div>
    <div className="gallery-container">
      <h1 className="gallery-heading">
        Make Your Way into Our Luxury Services
      </h1>
  
      <PhotoAlbum
         
        photos={photos}
        layout="masonry"
        onClick={({ index }) => setIndex(index)}
      />


      <Lightbox
        
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow,  Zoom]}
      />
      </div>
      <Footer/>
    </div>
  );
};

export default Gallery;