import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import ScheduleIcon from '@mui/icons-material/Schedule';

import '../styles/Footer.css';
import ClickToCallButton from './ClickToCallButton';

const Footer: React.FC = () => {
  return (
    <footer className="footer" style={{padding: '50px 10%'}}>
      <div className="footer-container">
        <div className="footer-column footer-address-column">
          <div className="footer-section">
            <div className="footer-contact-item">
              <div>
                <h3 className="footer-heading"><LocationOnIcon className="footer-icon" /> Address</h3>
                <div className="footer-contact-info">
                  <p className="footer-sub-info">Shop No.3, 2/8, Darshan Heights, near Basket Bridge,</p>
                  <p className="footer-sub-info">Oppo. Surya Mangal Banquet Hall, Punawale,</p>
                  <p className="footer-sub-info">Pimpri-Chinchwad, Maharashtra 411033</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-column footer-contact-column">
          <div className="footer-section">
            <div className="footer-contact-item">
              <div>
                <h3 className="footer-heading"><PhoneIcon className="footer-icon" /> Get in touch</h3>
                <div className="footer-contact-info">
                  <p className="footer-sub-info">Phone: +91 8623917369<br/>Email: rejuvenatebeautysalonandspa@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-column footer-hours-column">
          <div className="footer-section">
            <div className="footer-contact-item">
              <div>
                <h3 className="footer-heading"><ScheduleIcon className="footer-icon" /> Business hours</h3>
                <div className="footer-contact-info">
                  <p className="footer-sub-info">
                    Tuesday to Sunday: 11 AM to 7.30 PM<br />
                    Monday: Closed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ClickToCallButton/>
      </div>
      <div className="footer-bottom">
        <p className="footer-copyright">Copyright© All rights reserved-</p>
        <p>Designed By <a href="https://www.technocirrus.com/">TechnoCirrus Solutions</a> with ❤️</p>
      </div>
    </footer>
  );
}

export default Footer;
