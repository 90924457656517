// massageData.ts

export interface SpaData {
    title: string;
    description: string;
    price: number;
    image: string;
  }
  
  export const massageData: SpaData[] = [
    {
      title: "Spa",
      description: "Indulge in the ultimate relaxation experience at Rejuvenate Spa, where luxury meets tranquility. ",
      price: 500,
      image: "/assets/swedish-massage.jpg"
    },
    {
      title: "Steam Bath",
      description: "Experience the soothing embrace of our Steam Bath, a luxurious and rejuvenating escape from the stresses of everyday life. ",
      price: 600,
      image: "/assets/deep-tissue-massage.jpg"
    },
    // {
    //   title: "Hot Stone Spa",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   price: 700,
    //   image: "/assets/hot-stone-massage.jpg"
    // },
    // Add more massage services as needed
  ];
  