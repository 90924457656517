
export interface servicesDat {
    title: string;
    description: string;
    price: number;
    image: string;
  }
  

export const servicesData = [
    {
      title: "Hair Cuts & Styling",
      description: "At Rejuvenate, you can trust our hairstylists to give you the latest hairstyle, while keeping in mind your preferences. New hairstyles come with a relaxing hair wash and conditioning, giving you a natural shine.",
      image: "/assets/Haircut.jpg",
      price: 500
    },
    {
      title: "Hair Coloring & Highlights",
      description: " our expert colorists understand the transformative power of hair coloring and highlights. Trust us to not only provide you with the latest trends in hair color, but also to tailor them to your unique preferences and style. ",
      image: "/assets/Haircolor.jpg",
      price: 500
    },
    {
        title: "Hair Spa",
        description: "Hair Spa is more than just a treatment—it's an indulgent experience designed to revitalize your hair and spirit. Step into our sanctuary and let our skilled professionals pamper your locks with nourishing treatments tailored to your hair's unique needs.",
        image: "/assets/Hairspa.jpg",
        price: 500
      },
      {
        title: "Hair Straightening",
        description: "At Rejuvenate, we understand the allure of sleek, straight hair, and our hair straightening services are designed to help you achieve that effortlessly polished look you desire. ",
        image: "/assets/Hairstraightning.jpg",
        price: 500
      },
      {
        title: "Hair Keratin",
        description: "we believe that healthy, beautiful hair starts from within, which is why we offer luxurious keratin treatments to nourish and strengthen your locks from the inside out",
        image: "/assets/HairKeratin.jpg",
        price: 500
      },
    // Add more service objects here
  ];
  