export const manicurePedicureData = [
    {
      title: "Manicure",
      image: "/assets/Manicure.jpg",
      description: "At Rejuvenate, we offer three unique manicures – Spa Manicure, Radiant Manicure and Intense Moisturizing Manicure.",
      price: 500,
    },
    {
      title: "Pedicure",
      image: "/assets/Pedicure.jpg",
      description: "we believe that pampering your feet is essential to overall well-being, which is why our pedicure services are designed to provide both relaxation and rejuvenation",
      price: 500,
    },
    {
      title: "Reflexology",
      image: "/assets/Reflexology.jpg",
      description: "we recognize the importance of holistic wellness, which is why we offer reflexology services to help you achieve balance and harmony both physically and mentally. ",
      price: 500,
    },
    {
      title: "Nailart",
      image: "/assets/Nailart.jpg",
      description: "we understand that your nails are the perfect canvas for self-expression, which is why we offer an array of nail art services to help you express your unique style",
      price: 500,
    },
  ];
  