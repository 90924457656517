export interface MakeupService {
  title: string;
  description: string;
  price: number;
  image: string;
}

export const makeupServiceData: MakeupService[] = [
  {
    title: 'Pre-bridal makeup',
    description: 'Pre-bridal preparation is vital in achieving a flawless and radiant bridal makeup look. Before the wedding, a range of skincare and beauty regimens are included. The objective is to bring out the bride\'s natural beauty, ensure her skin is in perfect condition, and add skincare that will stay all day.',
    price: 500,
    image: '/assets/Prebridal.jpg'
  },
  {
    title: 'Bridal makeup',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    price: 500,
    image: '/assets/Bridal.jpg'
  },
  {
    title: 'Party makeup',
    description: 'Get ready to slay at your next party with our stunning party makeup services. Whether it\'s a glamorous evening event or a fun night out with friends, we\'ll make sure you shine and stand out!',
    price: 300,
    image: '/assets/Party.jpg'
  },
  {
    title: 'Day makeup',
    description: 'Achieve a fresh and natural look with our day makeup services. Perfect for daytime events, work, or casual outings, our makeup artists will enhance your features while keeping it light and effortless.',
    price: 250,
    image: '/assets/Day.jpg'
  },
  {
    title: 'Night makeup',
    description: 'Transform into the belle of the ball with our captivating night makeup services. Whether it\'s a romantic dinner or a night on the town, we\'ll create a mesmerizing look that will leave a lasting impression.',
    price: 350,
    image: '/assets/Night.jpg'
  },
  {
    title: 'Engagement makeup',
    description: 'Celebrate your love story with our exquisite engagement makeup services. From soft and romantic to bold and dramatic, we\'ll help you achieve the perfect look for your special day.',
    price: 400,
    image: '/assets/Engagement.jpg'
  },
  // {
  //   title: 'Pre-wedding makeup',
  //   description: 'Prepare for your big day with our comprehensive pre-wedding makeup services. Whether it\'s a pre-wedding photoshoot or final preparations before the ceremony, we\'ll ensure you look radiant and picture-perfect.',
  //   price: 450,
  //   image: '/assets/PreWedding.jpg'
  // },
  // Add more makeup services here as needed
];
