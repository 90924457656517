import React from 'react';
import { Link } from 'react-router-dom';
import { LocalHospital, Face, Spa, EmojiPeople, Weekend, Face3, Mood, FaceRetouchingNatural } from '@mui/icons-material';
import Footer from './Footer';
import '../styles/WartRemoval.css'; // Ensure this CSS file includes the new styles

const WartRemoval: React.FC = () => {
  return (
    <div>
      <div className="wart-removal-video-container">
        {/* Video or Image of Wart Removal */}
        <img src="/assets/wart-removal-1.jpg" alt="Wart Removal" />
      </div>

      <div className="wart-removal-service-details">
        <h3 className="wart-removal-title">Wart Removal Services</h3>
        <p className="wart-removal-info">
          Our wart removal services offer safe and effective solutions to rid you of unwanted warts. Whether it's common warts, plantar warts, or genital warts, our skilled professionals utilize advanced techniques to help you achieve smooth, blemish-free skin.
        </p>
      </div>

      <div className="wart-removal-other-services-container">
        <div className="wart-removal-other-services">
          <h3>Explore Other Services:</h3>
          <div className="wart-removal-service-buttons">
            <div className="wart-removal-service-button">
              <Link to="/services/manicure-pedicure">
                <Weekend />
                <span>Manicure & Pedicure</span>
              </Link>
            </div>
            <div className="wart-removal-service-button">
              <Link to="/services/facial-skincare">
                <Face />
                <span>Facial & Skin Care</span>
              </Link>
            </div>
            <div className="wart-removal-service-button">
              <Link to="/services/haircut-styling">
                <LocalHospital />
                <span>Haircut & Styling</span>
              </Link>
            </div>
            <div className="wart-removal-service-button">
              <Link to="/services/spa">
                <Spa />
                <span>Spa Service</span>
              </Link>
            </div>
            <div className="wart-removal-service-button">
              <Link to="/services/waxing">
                <EmojiPeople />
                <span>Waxing Service</span>
              </Link>
            </div>
            <div className="wart-removal-service-button">
              <Link to="/services/makeup">
                <FaceRetouchingNatural/>
                <span>Makeup Service</span>
              </Link>
            </div>
            <div className="wart-removal-service-button">
              <Link to="/services/permanent-eyebrow">
                <Face3 />
                <span>Permanent Eyebrow</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default WartRemoval;
